import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../../Assets/Logo.png";
import "./ResetForm.css";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { globalApiCalling } from "../../Utils/globalApiCall";
import { isValidForm, validateFormData } from "../../Utils/formValidation";

const ResetForm = () => {
  const { t } = useTranslation();
  const [formData, setFormState] = useState({
    email: "",
    password: "",
    currentPassword: "",
  });
  const [errors, setErrors] = useState({});

  const location = useLocation();
  const navigate = useNavigate();

  // Extract the token from the URL
  const token = new URLSearchParams(location.search).get("token");

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
    setErrors(""); // Clear error message on input change
  };

  const handleEmailSent = async (e) => {
    e.preventDefault();

    const validationErrors = validateFormData(formData, t, "newPassword");
    setErrors(validationErrors);
    if (!isValidForm(validationErrors)) {
      return;
    }

    try {
      const response = await globalApiCalling(
        "POST"`doublefollowers/v1/reset/password`,
        {
          email: formData.email,
          password: formData.password,
          token: token,
        }
      );
      const data = await response?.json();
      if (data?.code === 200 || data?.code === 1000) {
        toast.success(t("Success!"));
        navigate("/");
      } else {
        console.error(t("Error during password reset"), data?.data);
        toast.error(data?.data || t("Error during password reset"));
      }
    } catch (error) {
      console.error(t("Error during password reset"), error);
      toast.error(error?.message);
    }
  };

  return (
    <div className="reset-form">
      <img src={logo} alt="Logo" className="logo" />
      <form className="form" onSubmit={handleEmailSent}>
        <h2>{t("Reset Password")}</h2>
        <div className="text-1 mb-[40px] mt-[10px]">
          <p>
            {t("Please enter your email address to request a password reset.")}
          </p>
        </div>
        <div className="input-container">
          <label htmlFor="email">{t("Email")}</label>
          <input
            type="email"
            id="email"
            className={` input ${
              errors.email ? "input-error" : "input-border"
            }`}
            value={formData.email}
            onChange={handleInputChange}
          />
          {errors.email && (
            <div className="text-red-500 mt-1 mb-2 text-sm">{errors.email}</div>
          )}
        </div>
        <div className="input-container">
          <label htmlFor="password">{t("New Password*")}</label>
          <input
            type="password"
            id="password"
            className={` input ${
              errors.password ? "input-error" : "input-border"
            }`}
            value={formData.password}
            onChange={handleInputChange}
          />
          {errors.password && (
            <div className="text-red-500 mt-1 mb-2 text-sm">
              {errors.password}
            </div>
          )}
        </div>
        <div className="input-container">
          <label htmlFor="currentPassword">{t("Confirm Password")}</label>
          <input
            type="password"
            id="currentPassword"
            className={` input ${
              errors.currentPassword ? "input-error" : "input-border"
            }`}
            value={formData.currentPassword}
            onChange={handleInputChange}
          />
          {errors.currentPassword && (
            <div className="text-red-500 mt-1 mb-2 text-sm">
              {errors.currentPassword}
            </div>
          )}
        </div>
        <button type="submit" className="reset-button">
          {t("Reset")}
        </button>
      </form>
      <div className="copyright">
        <p>{t("Copyright © 2024 Smart Message Pioneer | Privacy Policy")}</p>
      </div>
    </div>
  );
};

export default ResetForm;
