
import React, { useEffect, useRef, useState } from "react";
import userImage from "../../../Assets/user.png";
import style from "./Chat.module.css";
import SvgComponent from "../../SvgComponent/SvgComponent";
import { useTranslation } from "react-i18next";
import { isThisYear } from "date-fns";

const Chat = ({
  handleMessage,
  setChat,
  selectedAccountID, 
  messagesData,
  userName,
  chat,
  contactId,
  error,
  setRealTimeMessages,
  realTimeMessages
}) => {
  const { t } = useTranslation();  

  const [previousContactId, setPreviousContactId] = useState(null);

  // Handle input change and update via setChat (received from parent)
  const handleChange = (e) => {
    setChat(e.target.value);
  };

  useEffect(() => {
    if (previousContactId !== contactId) {
      setRealTimeMessages([]);
      setPreviousContactId(contactId);
    }
  }, [contactId, previousContactId]);

  useEffect(() => {
    const socket = new WebSocket(process.env.REACT_APP_WEBSOCKET_URL);

    // socket.onopen = () => {
    //   console.log("WebSocket connection opened");
    // };

    socket.onopen = () => {
      console.log("WebSocket connection opened");
  
      // Send a "ping" message every 30 seconds to keep the connection alive
      const pingInterval = setInterval(() => {
        if (socket.readyState === WebSocket.OPEN) {
          socket.send(JSON.stringify({ type: "ping" }));
        }
      }, 30000); // 30 seconds interval
  
      // Clear the interval when the component is unmounted
      return () => clearInterval(pingInterval);
    };
  

    socket.onmessage = function (event) {
      const messageData = JSON.parse(event.data);

      // Validation: Skip empty messages
    if (
      !messageData.message ||
      messageData.senderTimestamp === 0 ||
      !messageData.status
    ) {
      console.log("Invalid message received, skipping:", messageData);
      return; // Skip adding this message to the state
    }
      // Format timestamp
      const formattedMessage = {
        Message: messageData.message,
        Direction: "incoming", // or 'outgoing' based on your logic
        CreatedAt: new Date(messageData.senderTimestamp * 1000).toISOString(),
        avatar: userImage, // Use the avatar image
        status: messageData.status || "Sent",
        username: messageData.username || "Customer",
      };

      console.log(
        "New message received from WebSocket:",
        messageData,
        formattedMessage.CreatedAt
      );

      // Append the new message to the state
      setRealTimeMessages((prevMessages) => [
        ...prevMessages,
        formattedMessage,
      ]);
    };

    socket.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    socket.onclose = () => {
      console.log("WebSocket connection closed");
    };

    return () => {
      socket.close();
    };
  }, []);

  
  const chatContainerRef = useRef(null);

  useEffect(() => {    
      chatContainerRef.current?.scrollTo({
        top: chatContainerRef.current.scrollHeight,
        behavior: "smooth",
      });
    
  }, [realTimeMessages,handleMessage]);

  // Function to format time into 12-hour format
  const formatTime = (date) => {
    const parsedDate = new Date(Date.parse(date));
    let hours = parsedDate.getHours();
    let minutes = parsedDate.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    return `${hours}:${minutes} ${ampm}`;
  };

  const formatDateLine = (dateString) => {
    // Ensure dateString is correctly parsed
    const date = new Date(dateString); 
  
    if (isNaN(date.getTime())) {
      console.error(`Invalid date: ${dateString}`);
      return "Invalid Date"; // Return this for debugging or handle it accordingly
    }
  
    const today = new Date();
    const yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);
  
    // Ignore the time part of the date for comparison
    const isToday = date.toDateString() === today.toDateString();
    const isYesterday = date.toDateString() === yesterday.toDateString();
  
    console.log({ isToday });
    console.log({ isYesterday });
  
    // Return 'Today', 'Yesterday', or formatted date for older messages
    if (isToday) {
      return t("Today");
    } else if (isYesterday) {
      return t("Yesterday");
    } else {
      return date.toLocaleDateString("en-US", {
        weekday: "long", // Show day of the week for older messages
        month: "short", // Show short month name
        day: "numeric",
      });
    }
  };

  const sortMessagesByTime = (messages) => {
    return messages.sort(
      (a, b) => new Date(a.CreatedAt) - new Date(b.CreatedAt)
    );
  };

  const sortedMessages = sortMessagesByTime([
    ...messagesData,
    ...realTimeMessages,
  ]);
  // Function to display a date line between different days

  // const renderDateLine = (currentDate, previousDate) => {
  //   if (currentDate !== previousDate) {
  //     return (
  //       <div className="flex justify-center items-center my-4 text-xs">
  //         <div className="border-t border-[#E8E8E8] flex-grow"></div>
  //         <span className="mx-4 text-[#949494] font-semibold">
  //           {currentDate}
  //         </span>
  //         <div className="border-t border-[#E8E8E8] flex-grow"></div>
  //       </div>
  //     );
  //   }
  //   return null;
  // };
  // Render date line based on current and previous dates
// const renderDateLine = (currentDate, previousDate) => {
//   // Ensure both currentDate and previousDate are valid date strings
//   if (currentDate && previousDate) {
//     const currentParsedDate = new Date(currentDate);
//     const previousParsedDate = new Date(previousDate);

//     if (isNaN(currentParsedDate.getTime()) || isNaN(previousParsedDate.getTime())) {
//       console.error(`Invalid date in messages: currentDate: ${currentDate}, previousDate: ${previousDate}`);
//       return null;
//     }

//     // Compare dates and render date line only if they are different
//     if (currentParsedDate.toDateString() !== previousParsedDate.toDateString()) {
//       return (
//         <div className="flex justify-center items-center my-4 text-xs">
//           <div className="border-t border-[#E8E8E8] flex-grow"></div>
//           <span className="mx-4 text-[#949494] font-semibold">
//             {formatDateLine(currentDate)}
//           </span>
//           <div className="border-t border-[#E8E8E8] flex-grow"></div>
//         </div>
//       );
//     }
//   } else if (currentDate && !previousDate) {
//     // Render date line for the first message
//     return (
//       <div className="flex justify-center items-center my-4 text-xs">
//         <div className="border-t border-[#E8E8E8] flex-grow"></div>
//         <span className="mx-4 text-[#949494] font-semibold">
//           {formatDateLine(currentDate)}
//         </span>
//         <div className="border-t border-[#E8E8E8] flex-grow"></div>
//       </div>
//     );
//   }

//   return null;
// };

  return (
    <div className="flex flex-col h-full bg-white shadow-md border-radius-12 chatContainer flex-grow">
      {/* Display User Info */}
      {selectedAccountID && contactId && (
        <div
          className="flex items-center p-4 relative"
          style={{ boxShadow: "0px 4px 4px 0px #2455FF0D" }}
        >
          <img
            src={userImage}
            alt={t("Customer")}
            className="h-10 w-10 rounded-full mr-3"
          />
          <h2 className="text-base font-semibold">{userName}</h2>
          <span
            className={`ml-auto h-3 w-3 bg-green-500 rounded-full online-tag ${style.onlineTag}`}
          ></span>
        </div>
      )}

    
      <div className="flex-1 overflow-auto p-4 space-y-4" ref={chatContainerRef}>

        {sortedMessages.length > 0 ? (
          sortedMessages.map((msg, index) => (
            <React.Fragment key={index}>
              {/* {renderDateLine(
                formatTime(msg.CreatedAt),
                index > 0 ? messagesData[index - 1]?.CreatedAt : null
              )} */}
              <div
                className={`flex ${
                  msg.Direction === "outgoing" ? "justify-end" : ""
                }` }
              >
                <div
                  className={`flex gap-2 ${
                    msg.Direction === "outgoing" ? "flex-row-reverse" : ""
                  } items-end space-x-2 space-x-reverse`}
                >
                  {/* <img
                    src={msg.avatar || userImage}
                    alt={msg.sender}
                    className="h-8 w-8 rounded-full self-baseline"
                  /> */}
                  <div
                    className={`flex flex-col gap-2.5 ${
                      msg.Direction === "outgoing" ? "items-end" : "items-start"
                    }`}
                  >
                    {/* <span
                      className={`text-sm text-[#303030] font-medium ${
                        msg.Direction === "outgoing"
                          ? "text-right"
                          : "text-left"
                      }`}
                    >
                      {msg.Direction === "outgoing"
                        ? "Customer Service Agent"
                        : "Customer"}
                    </span> */}
                    <div
                      className={`max-w-xs p-3 rounded-[12px] ${
                        msg.Direction === "outgoing"
                          ? "bg-[#696CFF] text-white rounded-tr-none"
                          : "bg-[#EFEFEF] text-gray-800 rounded-tl-none"
                      }`}
                    >
                      <p className="text-sm">{msg?.Message || msg?.message}</p>
                    </div>
                    <div className="flex items-center space-x-1 text-xs text-gray-500">
                      {msg.Direction === "outgoing" ? (
                        <SvgComponent name={"doubleTick"} />
                      ) : (
                        ''
                        // <SvgComponent name={"singleTick"} />
                      )}
                      <span className="text-xs text-[#687779]">
                        {formatTime(msg?.CreatedAt)}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </React.Fragment>
          ))
        ) : (
          <div className="flex flex-col items-center justify-center h-full">
            <div className="icon-wrapper bg-white p-6 rounded-full shadow-custom">
              <SvgComponent name={"chatBox"} />
            </div>
            <button className="start-conversation-button bg-white mt-6 px-6 py-3 rounded-full shadow-custom text-[#939FBF] text-lg">
              {t("Start Conversation")}
            </button>
          </div>
        )}
      </div>

      {/* Chat Input Box */}
      { selectedAccountID && contactId && (
        <div className="relative" style={{ padding: "1rem" }}>
          <input
            type="text"
            value={chat}
            // placeholder={t("Type here...")}
            placeholder={error ? t("Type here...") : "Chat disabled"}
            className="placeholder:text-[#867B7B] w-full p-2 border border-radius-8 bg-blue-100 text-sm bg-[#F0F3FF] text-[#303030]"
            style={{ padding: "1rem 0.5rem" }}
            onChange={handleChange} 
            disabled={!error}

          />
          <span 
  className={`${style.sendMessagebtn}`} 
  onClick={() => {
    handleMessage();
  }}>
  <SvgComponent name={"sendMessage"} />
</span>

        </div>
      )}
    </div>
  );
};

export default Chat;