import React, { useEffect, useRef, useState } from "react";
import Header from "../Header/Header";
import StatsCard from "./StatusDetail/StatsCard";
import AccountsSidebar from "./AccountsSidebar/AccountsSidebar";
import FriendsList from "./FriendList/FriendsList";
import Chat from "./Chat/Chat";
import MaterialLibrary from "./RightPanel/MaterialLibrary";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { globalApiCalling } from "../../Utils/globalApiCall";
import { useFollowerContext } from "../../Utils/Context/Context";

const AgentDashBoard = () => {
  const { t } = useTranslation();
  const [accounts, setAccounts] = useState([]);
  const [contactId, setContactId] = useState("");
  const [filteredAccounts, setFilteredAccounts] = useState([]);
  const { selectedAccountID, setSelectedAccountId, selectedContactId } =
    useFollowerContext();
  const [messages, setMessages] = useState([]);
  const [openSearch, setOpenSearch] = useState(false);
  const [chat, setChat] = useState("");
  const [userName, setUserName] = useState("");
  const [closeChat, setCloseChat] = useState(false);
  console.log({ closeChat });
  const isFirstRender = useRef(true);
  const [error, setError] = useState(false);
  const [realTimeMessages, setRealTimeMessages] = useState([]);

  const handleUserName = (data) => {
    setUserName(data);
  };

  const handleCloseChat = () => {
    setCloseChat(true);
  };


  const getRandomColorPair = () => {
    const colorPairs = [
      { bg: "bg-red-100", text: "text-red-500" },
      { bg: "bg-green-100", text: "text-green-500" },
      { bg: "bg-blue-100", text: "text-blue-500" },
      { bg: "bg-yellow-100", text: "text-yellow-500" },
      { bg: "bg-purple-100", text: "text-purple-500" },
      { bg: "bg-pink-100", text: "text-pink-500" },
      { bg: "bg-indigo-100", text: "text-indigo-500" },
    ];
    return colorPairs[Math.floor(Math.random() * colorPairs.length)];
  };

  const handleMessage = async (e) => {
    if (chat) {
      const trimmedMessage = chat.trim();

      if (!trimmedMessage) {
        toast.error("Chat message is empty");
        return;
      }
    }

    try {
      const response = await globalApiCalling(
        "POST",
        `doublefollowers/v1/message/send`,
        {
          acc_id: selectedAccountID,
          contact_id: contactId,
          message: chat,
        }
      );
      const data = await response?.json();

      // console.log

      if (data?.code === 1000 || data?.code === 200) {
        // setReceiveMessage(data.data.message)
        setChat(" ");
        window.scrollTo(500, 500);
      }
    } catch (error) {
      console.error("Error fetching accounts:", error);
      toast.error(error?.message);
    }
  };

  const handlelistenMessage = async (e) => {
    try {
      const response = await globalApiCalling(
        "GET",
        `/doublefollowers/v1/messages/listen?contact_id=${contactId}&acc_id=${selectedAccountID}`
      );
      const data = await response?.json();
      console.log("accDataListen::", data);

      if (data?.code === 1000 || data?.code === 200) {
        setError(true);
        // setChat(" ");
      }
    } catch (error) {
      console.error("Error fetching accounts:", error);
      toast.error(error?.message);
    }
  };

  const handleContactIdChange = (newContactId) => {
    setContactId(newContactId);
  };

  useEffect(() => {
    const fetchAccounts = async () => {
      try {
        const response = await globalApiCalling(
          "GET",
          `doublefollowers/v1/account/list`
        );
        const data = await response?.json();

        console.log("Acc::", data);
        if (data?.code === 1000 || data?.code === 200) {
          const formattedAccounts = data?.data?.map((account) => ({
            ID: account?.Id,
            PhoneNumber: account?.PhoneNumber,
            OnlineStatus: account?.OnlineStatus,
            label: account?.PhoneNumber.substring(0, 4),
            colorPair: getRandomColorPair(), // Assign random color pair
          }));
          setSelectedAccountId(formattedAccounts?.[0]?.ID);
          setAccounts(formattedAccounts);
          setFilteredAccounts(formattedAccounts);
        }
      } catch (error) {
        console.error("Error fetching accounts:", error);
        toast.error(error?.message);
      }
    };

    fetchAccounts();
  }, []);

  const filterAccounts = (searchTerm) => {
    if (searchTerm) {
      const filtered = accounts.filter((account) =>
        account.PhoneNumber.includes(searchTerm)
      );
      setFilteredAccounts(filtered);
    } else {
      setFilteredAccounts(accounts);
    }
  };

  const fetchMessages = async () => {
    try {
      const response = await globalApiCalling(
        "GET",
        `doublefollowers/v1/messages/list?contact_id=${contactId}&acc_id=${selectedAccountID}`
      );
      const data = await response?.json();

      if (data?.code === 1000 || data?.code === 200) {
        setMessages(data.data);
      }
    } catch (error) {
      console.error("Error fetching accounts:", error);
      toast.error(error?.message);
    }
  };
  const closeConnection = async () => {
    try {
      const response = await globalApiCalling(
        "GET",
        `doublefollowers/v1/closeConnection?acc_id=${selectedAccountID}`
      );
      const data = await response?.json();

      if (data?.code === 1000 || data?.code === 200) {
        // setMessages(data.data);
      }
    } catch (error) {
      console.error("Error fetching accounts:", error);
      toast.error(error?.message);
    }
  };

  useEffect(() => {
    if (!contactId && !isFirstRender.current) {
      closeConnection();
    }
    isFirstRender.current = false;
  }, [contactId]);

  useEffect(() => {
    if (contactId && selectedAccountID) {
      handlelistenMessage();
    }
  }, [contactId, selectedAccountID]);

  useEffect(() => {
    if (contactId && selectedAccountID) {
      fetchMessages();
      setRealTimeMessages([]);
    }
  }, [contactId, selectedAccountID, chat]);

  const resetAccounts = () => {
    setFilteredAccounts(accounts);
  };
  const handleSearch = () => {
    setOpenSearch(!openSearch);
  };

  return (
    //if no data then below comented code
    //   <div className="flex-grow bg-gray-50 p-4 flex justify-center items-center">
    //   <span className="text-gray-400">Start Conversation</span>
    // </div>
    <div className="flex flex-col h-screen">
      <Header />
      <StatsCard />
      <div
        className="flex flex-grow bg-[#f3f5fb] p-4 gap-4 pt-0 min-h-screen"
        style={{ maxHeight: "100%" }}
      >
        {/* <div className="w-1/4"> */}
        {/* <Sidebar /> */}
        <div className="flex bg-[#f3f5fb] gap-4">
          <AccountsSidebar
            setAccounts={setAccounts}
            setFilteredAccounts={setFilteredAccounts}
            filterAccounts={filterAccounts}
            setSelectedAccountId={setSelectedAccountId}
            setContactId={setContactId}
            setMessages={setMessages}
            accounts={accounts}
            filteredAccounts={filteredAccounts}
            selectedAccountID={selectedAccountID}
            handleCloseChat={handleCloseChat}
          />
          {/* <div className="flex-grow p-6"> */}
          <FriendsList
            onContactIdChange={handleContactIdChange}
            handleUserName={handleUserName}
          />
          {/* </div> */}
        </div>
        {/* </div> */}
        <div className="w-2/4 flex flex-col flex-1">
          {/* <div className="grid grid-cols-4 gap-4 p-4 bg-white shadow">
            <StatsCard title="Total Friends" value="0" />
            <StatsCard title="New Friends" value="0" />
            <StatsCard title="Today's Heavy Fans" value="0" />
            <StatsCard title="Total Duplicates" value="0" />
          </div> */}
          <Chat
            handleMessage={handleMessage}
            chat={chat}
            setChat={setChat}
            selectedAccountID={selectedAccountID}
            contactId={contactId}
            messagesData={messages}
            userName={userName}
            handlelistenMessage={handlelistenMessage}
            error={error}
            // handleRealChat={handleRealChat}
            setRealTimeMessages={setRealTimeMessages}
            realTimeMessages={realTimeMessages}
          />
        </div>
        <div className="h-full" style={{ width: "23%" }}>
          <MaterialLibrary />
        </div>
      </div>
    </div>
  );
};

export default AgentDashBoard;
