import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import overlay from "../../Assets/SignUp.png";
import logo from "../../Assets/Logo.png";
import "./LogIn.css";
import { toast } from "react-toastify";
import { useFollowerContext } from "../../Utils/Context/Context";
import { clearCookie, handleLocalStorageClear } from "../../Utils/helper";
import { useTranslation } from "react-i18next";
import Header from "../Header/Header";
import { globalApiCalling } from "../../Utils/globalApiCall";
import { isValidForm, validateFormData } from "../../Utils/formValidation";

const LogIn = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setProfileData } = useFollowerContext();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({});

  // console.log("Errorss::", errors);

  const handleChange = (e) => {
    const { name, value } = e.target;

    console.log("Val::", value);
    setFormData((prev) => ({ ...prev, [name]: value }));
    // Clear the error when the user starts typing
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const handleSignup = (e) => {
    e.preventDefault();
    navigate("/signup");
  };

  const handlePwReset = (e) => {
    e.preventDefault();
    navigate("/reset-pw");
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    const validationErrors = validateFormData(formData, t, "login");
    setErrors(validationErrors);
    if (!isValidForm(validationErrors)) {
      console.log("The best::", validationErrors);

      return;
    }

    try {
      const response = await globalApiCalling(
        "POST",
        `doublefollowers/v1/user/login`,
        {
          email: formData?.email,
          password: formData?.password,
        }
      );

      const data = await response?.json();

      if (data?.code === 200 || data?.code === 1000) {
        // function getFullCookie(name) {
        //   const value = `; ${document.cookie}`;
        //   const parts = value.split(`; ${name}=`);
        //   if (parts.length === 2) return parts.pop().split(";").shift();
        //   return null;
        // }
        // const authSession = await getFullCookie("auth_session");
        // console.log(`auth_session=${authSession}`);

        const cookies1 = document.cookie;

        const originalString = `${data?.data?.role}!`;
        const encodedString = btoa(originalString);
        localStorage.setItem("role", encodedString);
        setProfileData({
          ...data?.data,
          gender: {
            label:
              data?.data?.gender?.charAt(0).toUpperCase() +
              data?.data?.gender?.slice(1).toLowerCase(),
            value:
              data?.data?.gender?.charAt(0).toUpperCase() +
              data?.data?.gender?.slice(1).toLowerCase(),
          },
        });
        localStorage.setItem(
          "profile",
          JSON.stringify({
            ...data?.data,
            gender: {
              label:
                data?.data?.gender?.charAt(0).toUpperCase() +
                data?.data?.gender?.slice(1).toLowerCase(),
              value:
                data?.data?.gender?.charAt(0).toUpperCase() +
                data?.data?.gender?.slice(1).toLowerCase(),
            },
          })
        );
        if (cookies1) {
          if (originalString === "AGENT!") {
            navigate("/dashboard");
            toast.success(t("Success!"));
          } else if (originalString === "USER!") {
            navigate("/account-management");
            toast.success(t("Success!"));
          } else if (originalString === "ADMIN!") {
            navigate("/admin/user-management");
            toast.success(t("Success!"));
          } else {
            clearCookie("auth_session");
            handleLocalStorageClear();
            navigate("/");
            toast.error(t("Access Denied"));
          }
        }
      } else {
        toast.error(data?.data || t("Error during Log in"));
      }
    } catch (error) {
      console.error(error?.message);
      toast.error(error?.message);
    }
  };

  return (
    <div className="auth-container login-page">
      <div className="left-side">
        <img src={logo} alt="Logo" className="overlay-logo" />
        <div className="overlay-text">
          {t("Elevate Your CRM with Smart Message Pioneer for WhatsApp!")}
        </div>
        <img src={overlay} alt="Overlay" className="overlay-image" />
      </div>

      <div className="right-side">
        <form className="form" onSubmit={handleLogin}>
          <div className="login-text mb-[35px]">
            <p>{t("Log in")}</p>
          </div>
          <div className="input-container">
            <label htmlFor="email">{t("Email")}</label>
            <input
              type="email"
              id="email"
              className={` input ${
                errors?.email ? "input-error" : "input-border"
              }`}
              name="email"
              value={formData.email}
              onChange={handleChange}
            />

            {errors?.email && (
              <div className="text-red-500 -mt-3 mb-2 text-sm">
                {errors.email}
              </div>
            )}
          </div>
          <div className="input-container">
            <label htmlFor="password">{t("Password")}</label>
            <input
              type="password"
              id="password"
              className={` input ${
                errors?.password ? "input-error" : "input-border"
              }`}
              name="password"
              value={formData.password}
              onChange={handleChange}
            />
            {errors?.password && (
              <div className="text-red-500 -mt-3 mb-2 text-sm">
                {errors.password}
              </div>
            )}
          </div>
          <button type="submit" className="button-signup">
            {t("Log In")}
          </button>
          <div className="forget-password" onClick={handlePwReset}>
            <p>{t("Forget your password")}</p>
          </div>
          <div className="text-1">
            <p>{t("New to Smart Message Pioneer")}</p>
          </div>
          <button type="button" className="button-login" onClick={handleSignup}>
            {t("Create an account")}
          </button>
        </form>
      </div>
    </div>
  );
};

export default LogIn;
