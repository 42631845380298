import React, { useState, useRef, useEffect } from "react";
import SvgComponent from "../SvgComponent/SvgComponent";
import { toast } from "react-toastify";
import { TagTypes } from "../../Utils/templateTypeEnum";
import { useTranslation } from "react-i18next";
import { globalApiCalling } from "../../Utils/globalApiCall";

const MaterialTagsUpsert = ({
  selectedType,
  setOpenModal,
  fetchTags,
  setTagId,
  tagId,
  tagType,
  tagTitle,
  fetchTagsAndFriends,
}) => {
  const { t } = useTranslation();
  const [tagName, setTagName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};

    if (!tagName) {
      newErrors.tagName = t("Name is required");
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const onClose = () => {
    setOpenModal(false);
    setTagId && setTagId(null);
  };

  const fetchSingletemplate = async () => {
    try {
      const response = await globalApiCalling(
        "GET",
        `doublefollowers/v1/tag?tag_id=${tagId}`
      );
      const data = await response?.json();
      if (data?.code === 200 || data?.code === 1000) {
        setTagName(data?.data?.Name);
      }
    } catch (error) {
      console.error("Error fetching media:", error);
      toast.error(error?.message);
    }
  };
  useEffect(() => {
    if (tagId) {
      fetchSingletemplate();
    }
  }, [tagId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (!validateForm()) {
      return;
    }
    try {
      let url;
      let method;
      let body;
      if (tagId) {
        url = `doublefollowers/v1/tag/update`;
        method = "PUT";
        body = {
          name: tagName,
          id: tagId,
        };
      } else {
        url = `doublefollowers/v1/tag/create`;
        method = "POST";
        body = {
          name: tagName,
          type: tagType,
        };
      }
      const response = await globalApiCalling(method, url, body);
      const data = await response?.json();
      if (data?.code === 200 || data?.code === 1000) {
        toast.success(t("Success!"));
        if (tagType !== TagTypes?.CONTACTS) {
          fetchTags();
        }
        fetchTagsAndFriends && fetchTagsAndFriends();
        setIsLoading(false);
        onClose();
      } else {
        toast.error(
          data?.data ||
            t(`Failed to ${tagId ? "update" : "create"} material tag!`)
        );
        setIsLoading(false);
      }
    } catch (error) {
      console.error(error);
      toast.error(error?.message);
      setIsLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[100]">
      <div className="bg-white rounded-lg shadow-lg w-[500px] p-6 relative">
        <button
          className="absolute top-4 right-4 w-[30px] h-[30px] bg-[#F0F3FF] flex items-center justify-center rounded-[23px]"
          onClick={onClose}
        >
          <SvgComponent name={"crossIcon"} />
        </button>
        <h2 className="text-xl font-semibold mb-4">
          {t("Add")} {t(tagTitle)}
        </h2>
        <form onSubmit={handleSubmit}>
          <div className="w-full">
            <label className="block mb-2 text-sm font-medium text-gray-700">
              {t(tagTitle)} {t("Name")}
            </label>
            <input
              type="text"
              value={tagName}
              onChange={(e) => setTagName(e.target.value)}
              className={`p-2 border-2 activeInput rounded-[8px] mb-4 w-full ${
                errors.tagName
                  ? "border-[#ef4444] input-error"
                  : "activeInput input-border"
              }`}
              placeholder={t("Enter Group name")}
            />
            {errors.tagName && (
              <div className="text-red-500 -mt-3 mb-2 text-sm">
                {errors.tagName}
              </div>
            )}
          </div>
          <div className="flex justify-end">
            <button
              type="button"
              onClick={onClose}
              className="mr-2 bg-gray-200 text-gray-700 px-4 py-2 rounded-lg hover:bg-gray-300"
            >
              {t("Cancel")}
            </button>
            <button
              type="submit"
              className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600"
            >
              {isLoading && tagName ? (
                <>
                  <i class="fa fa-circle-o-notch fa-spin mr-1"></i>
                  {t("Processing")}
                </>
              ) : tagId ? (
                t("Edit")
              ) : (
                t("Create")
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default MaterialTagsUpsert;
