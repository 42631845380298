export const validateFormData = (
  formData,
  t,
  validateSection,
  selectedSection,
  selectedOption
) => {
  const errors = {};
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const strongPasswordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

  if (validateSection === "faq") {
    if (selectedSection === "Category") {
      if (!formData.name) {
        errors.name = t("Category name is required");
      }
      if (!formData.description) {
        errors.description = t("Category description is required");
      }
    } else {
      if (!formData.question) {
        errors.question = t("Question is required");
      }
      if (!formData.answer) {
        errors.answer = t("Answer is required");
      }

      if (!selectedOption) {
        errors.category = t("Category selection is required");
      }
    }
    return errors;
  }
  if (validateSection === "category") {
    if (selectedSection === "Category") {
      if (!formData.name) {
        errors.name = t("Category name is required");
      } else if (!formData.description) {
        errors.description = t("Category description is required");
      }
    } else {
      if (!formData.question) {
        errors.question = t("Question is required");
      } else if (!formData.answer) {
        errors.answer = t("Answer is required");
      }

      if (!selectedOption) {
        errors.category = t("Category selection is required");
      }
    }
    return errors;
  }

  if (validateSection === "signup") {
    if (!formData.name) {
      errors.name = t("Name is required");
    }

    if (!formData.email) {
      errors.email = t("Email is required");
    } else if (!emailRegex.test(formData.email)) {
      errors.email = t("Please enter a valid email address");
    }

    if (!formData.password) {
      errors.password = t("Password is required");
    } else if (!strongPasswordRegex.test(formData.password)) {
      errors.password = t(
        "Password must be at least 8 characters long, include an uppercase letter, a lowercase letter, a number, and a special character"
      );
    }

    return errors;
  }

  if (validateSection === "login") {
    if (!formData.email) {
      if (!formData.email) {
        errors.email = t("Email is required");
      } else if (!emailRegex.test(formData.email)) {
        errors.email = t("Please enter a valid email address");
      }
    }

    if (!formData.password) {
      errors.password = t("Password is required");
      //   } else if (!strongPasswordRegex.test(formData.password)) {
      //     errors.password = t(
      //       "Password must be at least 8 characters long, include an uppercase letter, a lowercase letter, a number, and a special character"
      //     );
    }
    return errors;
  }
  if (validateSection === "resetPassword") {
    if (!formData.email) {
      errors.email = t("Email is required");
    } else if (!emailRegex.test(formData.email)) {
      errors.email = t("Please enter a valid email address");
    }

    return errors;
  }
  if (validateSection === "newPassword") {
    if (!formData.email) {
      errors.email = t("Email is required");
    } else if (!emailRegex.test(formData.email)) {
      errors.email = t("Please enter a valid email address");
    }
    if (!formData.password) {
      errors.password = t("Password is required");
    }

    if (!formData.currentPassword) {
      errors.currentPassword = t("Confirm Password is required");
    } else if (formData.password !== formData.currentPassword) {
      errors.currentPassword = t("Passwords do not match");
    }

    return errors;
  }
  if (validateSection === "add Image") {
    if (!formData.groupName) {
      errors.name = t("Name is required");
    }
    if (!formData.messageValue) {
      errors.name = t("Message is required");
    }

    return errors;
  }
  if (validateSection === "Create Agent") {
    if (!formData.name) {
      errors.name = t("Name is required");
    }
    if (!formData.email) {
      errors.email = t("Email is required");
    } else if (!emailRegex.test(formData.email)) {
      errors.email = t("Please enter a valid email address");
    }
    if (!formData.password) {
      errors.password = t("Password is required");
    }

    if (!formData.confirmPassword) {
      errors.confirmPassword = t("Confirm Password is required");
    } else if (formData.password !== formData.confirmPassword) {
      errors.confirmPassword = t("Passwords do not match");
    }

    return errors;
  }
  if (validateSection === "Help management") {
    if (!formData) {
      errors.name = t("Name is required");
    }

    // if (!formData.confirmPassword) {
    //   errors.confirmPassword = t("Confirm Password is required");
    // } else if (formData.password !== formData.confirmPassword) {
    //   errors.confirmPassword = t("Passwords do not match");
    // }

    return errors;
  }
};

export const isValidForm = (errors) => {
  return Object.keys(errors).length === 0;
};
