import React, { useState, useEffect } from "react";
import SvgComponent from "../SvgComponent/SvgComponent";
import { toast } from "react-toastify";
import { t } from "i18next"; // Assuming you're using i18next for localization
import { globalApiCalling } from "../../Utils/globalApiCall";
import { isValidForm, validateFormData } from "../../Utils/formValidation";

const AgentUpsert = ({
  setOpenModal,
  setAgentId,
  agentId,
  fetchTags,
  isLoading,
  setIsLoading,
}) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const [fileError, setFileError] = useState({
    emailError: "",
    passwordError: "",
  });
  const [errors, setErrors] = useState({});

  const onClose = () => {
    setOpenModal(false);
    setAgentId && setAgentId(null);
  };

  const fetchSingleAgent = async () => {
    try {
      const response = await globalApiCalling(
        "GET",
        `doublefollowers/v1/user/agent?id=${agentId}`
      );
      const data = await response?.json();
      if (data?.code === 200 || data?.code === 1000) {
        setFormData((prev) => ({
          ...prev,
          name: data?.data?.full_name,
          email: data?.data?.email,
        }));
      }
    } catch (error) {
      console.error(t("Error fetching media"), error);
      toast.error(error?.message);
    }
  };

  useEffect(() => {
    if (agentId) {
      fetchSingleAgent();
    }
  }, [agentId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));

    // Reset fileError when input changes
    setFileError((prev) => ({
      ...prev,
      [`${name}Error`]: "",
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    // const REGEX_EMAIL =
    //   /^[a-zA-Z0-9]+[a-zA-Z0-9_.+-]*@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]{2,}$/;

    // if (formData?.email && !REGEX_EMAIL.test(formData.email)) {
    //   setFileError((prev) => ({
    //     ...prev,
    //     emailError: "Invalid email format!",
    //   }));
    //   setIsLoading(false);
    //   return;
    // }

    // if (
    //   (formData?.password || formData?.confirmPassword) &&
    //   formData?.password !== formData?.confirmPassword
    // ) {
    //   setFileError((prev) => ({
    //     ...prev,
    //     passwordError: "Password & confirm password should be the same!",
    //   }));
    //   setIsLoading(false);
    //   return;
    const validationErrors = validateFormData(formData, t, "Create Agent");
    setErrors(validationErrors);
    if (!isValidForm(validationErrors)) {
      return;
    }

    try {
      let url;
      let method;
      const body = {
        fullname: formData?.name,
        email: formData?.email,
        role: "AGENT",
      };
      if (agentId) {
        url = `doublefollowers/v1/user/agent/update`;
        method = "PUT";
        body.id = agentId;
        if (formData?.password) {
          body.password = formData?.password;
        }
      } else {
        url = `doublefollowers/v1/user/agent/signup`;
        method = "POST";
        body.password = formData?.password;
      }
      const response = await globalApiCalling(method, url, body);
      const data = await response?.json();
      if (data?.code === 1000 || data?.code === 200) {
        toast.success(t(`Success!`));
        fetchTags();
        onClose();
        setIsLoading(false);
      } else if (data?.code === 2036) {
        toast.error(t("Agent email already exists!"));
        setIsLoading(false);
      } else {
        toast.error(t(`Failed to ${agentId ? "update" : "create"} agent!`));
        setIsLoading(false);
      }
    } catch (error) {
      console.error(error);
      toast.error(error);
      setIsLoading(false);
    }
  };

  // useEffect(() => {
  //   setTimeout(() => {
  //     setFileError({ emailError: "", passwordError: "" });
  //   }, 7000);
  // }, [fileError]);

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[100]">
      <div className="bg-white rounded-lg shadow-lg w-[650px] p-6 relative">
        <button
          className="absolute top-4 right-4 w-[30px] h-[30px] bg-[#F0F3FF] flex items-center justify-center rounded-[23px]"
          onClick={onClose}
        >
          <SvgComponent name={"crossIcon"} />
        </button>
        <h2 className="text-xl font-semibold mb-4">
          {t(agentId ? "Edit Agent" : "Create Agent")}
        </h2>
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-2 gap-[15px] ">
            <div className="w-full">
              <label className="block mb-1 text-base text-gray-700">
                {t("Name")}
              </label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className={`p-2 border-2 activeInput rounded-[8px] mb-4 w-full ${
                  errors.name
                    ? "border-[#ef4444] input-error"
                    : "activeInput input-border"
                }`}
                placeholder={t("Enter Agent Name")}
                // required
              />
              {errors.name && (
                <div className="text-red-500 -mt-3 mb-2 text-sm">
                  {errors.name}
                </div>
              )}
            </div>
            <div className="w-full">
              <label className="block mb-1 text-base text-gray-700">
                {t("Email")}
              </label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                // className={`p-2 border-2 activeInput rounded-[8px] mb-1 w-full ${
                //   fileError.emailError ? "border-red-500" : ""
                // }`}
                className={`p-2 border-2 activeInput rounded-[8px] mb-4 w-full ${
                  errors.email
                    ? "border-[#ef4444] input-error"
                    : "activeInput input-border"
                }`}
                placeholder={t("Enter Agent Email")}
                // required
                // pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                title={t("Please enter a valid email address")}
              />
              {errors.email && (
                <div className="text-red-500 -mt-3 mb-2 text-sm">
                  {errors.email}
                </div>
              )}
            </div>
            <div className="w-full">
              <label className="block mb-1 text-base text-gray-700">
                {t("Password")}
              </label>
              <input
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                // className={`p-2 border-2 activeInput rounded-[8px] mb-1 w-full ${
                //   fileError.passwordError ? "border-red-500" : ""
                // }`}
                className={`p-2 border-2 activeInput rounded-[8px] mb-4 w-full ${
                  errors.password
                    ? "border-[#ef4444] input-error"
                    : "activeInput input-border"
                }`}
                placeholder={t("Enter Agent Password")}
                // required={agentId ? false : true}
              />
              {errors.password && (
                <div className="text-red-500 -mt-3 mb-2 text-sm">
                  {errors.password}
                </div>
              )}
            </div>
            <div className="w-full">
              <label className="block mb-1 text-base text-gray-700">
                {t("Confirm Password")}
              </label>
              <input
                type="password"
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleChange}
                // className={`p-2 border-2 activeInput rounded-[8px] mb-1 w-full ${
                //   fileError.passwordError ? "border-red-500" : ""
                // }`}
                className={`p-2 border-2 activeInput rounded-[8px] mb-4 w-full ${
                  errors.confirmPassword
                    ? "border-[#ef4444] input-error"
                    : "activeInput input-border"
                }`}
                placeholder={t("Re-enter Password")}
                // required={agentId ? false : true}
              />
              {errors.confirmPassword && (
                <div className="text-red-500 -mt-3 mb-2 text-sm">
                  {errors.confirmPassword}
                </div>
              )}
            </div>
          </div>
          <div className="flex justify-end mt-[20px]">
            <button
              type="button"
              onClick={onClose}
              className="mr-2 bg-gray-200 text-gray-700 px-4 py-2 rounded-lg hover:bg-gray-300"
            >
              {t("Cancel")}
            </button>
            <button
              type="submit"
              className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600"
            >
              {isLoading && !errors ? (
                <>
                  <i className="fa fa-circle-o-notch fa-spin mr-1"></i>
                  {t("Processing")}
                </>
              ) : agentId ? (
                t("Edit")
              ) : (
                t("Create")
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AgentUpsert;
