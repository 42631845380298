import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import overlay from "../../Assets/SignUp.png";
import logo from "../../Assets/Logo.png";
import "./SignUp.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import { globalApiCalling } from "../../Utils/globalApiCall";
import { isValidForm, validateFormData } from "../../Utils/formValidation";

const SignUp = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
  });

  const [errors, setErrors] = useState({});

  const handleLogin = (e) => {
    e.preventDefault();
    navigate("/");
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };
  const handlePwReset = (e) => {
    e.preventDefault();
    navigate("/reset-pw");
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
    const validationErrors = validateFormData(formData, t, "signup");
    setErrors(validationErrors);
    if (!isValidForm(validationErrors)) {
      return;
    }

    try {
      const response = await globalApiCalling(
        "POST",
        `doublefollowers/v1/user/signup`,
        {
          fullname: formData.name,
          email: formData.email,
          password: formData.password,
          role: "USER",
        }
      );

      const data = await response?.json();
      if (data?.code === 200 || data?.code === 1000) {
        toast.success(t("Success!"));
        navigate("/");
      } else {
        console.error(t("Error during sign up"), data?.data);
        toast.error(data?.data || t("Something went wrong!"));
      }
    } catch (error) {
      console.error(t("Error during sign up"), error);
      toast.error(error?.message);
    }
  };

  return (
    <div className="auth-container signup-page">
      <div className="left-side">
        <img src={logo} alt="Logo" className="overlay-logo" />
        <div className="overlay-text">
          {t("Elevate Your CRM with Smart Message Pioneer for WhatsApp!")}
        </div>
        <img src={overlay} alt="Overlay" className="overlay-image" />
      </div>

      <div className="right-side">
        <form className="form" onSubmit={handleSignUp}>
          <div className="signin-text mb-[35px]">
            <p>{t("Sign up")}</p>
          </div>
          <div className="input-container">
            <label className="label">{t("Name")}</label>
            <input
              type="text"
              id="name"
              className={` input ${
                errors.name ? "input-error" : "input-border"
              }`}
              name="name"
              value={formData.name}
              onChange={handleChange}
            />
            {errors.name && (
              <div className="text-red-500 -mt-3 mb-2 text-sm">
                {errors.name}
              </div>
            )}
          </div>
          <div className="input-container">
            <label className="label">{t("Email")}</label>
            <input
              type="email"
              id="email"
              className={` input ${
                errors.email ? "input-error" : "input-border"
              }`}
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
            {errors.email && (
              <div className="text-red-500 -mt-3 mb-2 text-sm">
                {errors.email}
              </div>
            )}
          </div>
          <div className="input-container">
            <label className="label">{t("Password")}</label>
            <input
              type="password"
              id="password"
              className={` input ${
                errors.password ? "input-error" : "input-border"
              }`}
              name="password"
              value={formData.password}
              onChange={handleChange}
            />
            {errors.password && (
              <div className="text-red-500 -mt-3 mb-2 text-sm">
                {errors.password}
              </div>
            )}
          </div>
          <button type="submit" className="button-signup">
            {t("Sign Up")}
          </button>
          <div className="text-1 mt-[15px]">
            <p>
              {t("By continuing, you agree to the")}{" "}
              <a href="/terms-of-use" className="link anchor-link">
                {t("Terms of use")}
              </a>{" "}
              {t("and")}{" "}
              <a href="/privacy-policy" className="link anchor-link">
                {t("Privacy Policy")}
              </a>
              .
            </p>
          </div>
          <div className="forget-password" onClick={handlePwReset}>
            <p>{t("Forget your password")}</p>
          </div>
          <div className="text-2">
            <p>{t("Already have an account")}</p>
          </div>
          <button type="button" className="button-login" onClick={handleLogin}>
            {t("Log In")}
          </button>
        </form>
      </div>
    </div>
  );
};

export default SignUp;
