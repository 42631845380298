import React, { useEffect, useState } from "react";
import SvgComponent from "../../SvgComponent/SvgComponent";
import CommonTable from "../../common/CommonTable";
import Pagination from "../../Pagination/Pagination";
// import FAQUpsert from "./FAQUpsert";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import BreadCrumbs from "../../common/BreadCrumbs";
import { globalApiCalling } from "../../../Utils/globalApiCall";
import { formatDateDayMonthYear } from "../../../Utils/helper";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import SingleSelectDropDown from "../../common/SingleSelectDropDown";
import CreateHelp from "./CreateHelp";
import { useNavigate } from "react-router-dom";
import { isValidForm, validateFormData } from "../../../Utils/formValidation";

const HelpManagement = ({
  fetchTags,
  setTagId,
  tagId,
  tagType,
  tagTitle,
  fetchTagsAndFriends,
}) => {
  const { t } = useTranslation();
  const [selectedSection, setSelectedSection] = useState("Article");
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [showFAQUpsert, setShowFAQUpsert] = useState(false);
  const [faqData, setFaqData] = useState([]);
  const [articleData, setArticleData] = useState([]);
  const [headers, setHeaders] = useState(["Name", "Description", "Sections"]);
  const [faqId, setFaqId] = useState(null);
  const [selectedIds, setSelectedIds] = useState([]);

  const [searchInput, setSearchInput] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  // const [modalTitle, setModalTitle] = useState(`Add ${selectedSection}`);
  const [sectionTitle, setSectionTitle] = useState(`${selectedSection} Name`);
  const [totalPages, setTotalPages] = useState(1);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const breadcrumbItems = ["Admin", "Help"];
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [sections, setSections] = useState([]);
  const [newSection, setNewSection] = useState("");
  const [articleName, setArticleName] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [tagName, setTagName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [sectionName, setSectionName] = useState("");
  const [errors, setErrors] = useState({});

  const [sectionData, setSectionData] = useState([]);
  // const [formData, setFormData] = useState({
  //   name: "",
  // });

  const navigate = useNavigate();

  const onClose = () => {
    setSectionName(" ");
    setOpenModal(false);
  };

  // const handleOptionClick = (option) => {
  //   setSelectedOption(option);
  //   setIsOpen(false);
  // };

  // Handle adding a new section
  // const handleAddSection = () => {
  //   if (newSection.trim() === "") return;
  //   if (sections.includes(newSection)) {
  //     alert("Section already exists!");
  //     return;
  //   }
  //   setSections([...sections, newSection]);
  //   setNewSection("");
  // };

  // Handle saving the article
  // const handleSave = () => {
  //   if (!articleName.trim()) {
  //     alert("Please enter an article name.");
  //     return;
  //   }
  //   if (!selectedSection) {
  //     alert("Please select a section.");
  //     return;
  //   }
  //   const content = draftToHtml(convertToRaw(editorState.getCurrentContent()));
  //   const articleData = {
  //     articleName,
  //     section: selectedSection,
  //     content,
  //   };
  //   // Here you can handle the submission, e.g., send to an API
  //   console.log("Article Data:", articleData);
  //   alert("Article saved successfully!");
  //   // Reset the form if needed
  //   setArticleName("");
  //   setSelectedSection("");
  //   setEditorState(EditorState.createEmpty());
  // };

  const fetchArticles = async () => {
    let url;
    if (selectedSection === "Article") {
      url = `doublefollowers/v1/help-article/list?limit=${entriesPerPage}&page_no=${currentPage}&name=${searchInput}`;
    } else if (faqId) {
      url = `doublefollowers/v1/help-section/detail?id=${faqId}`;
    }

    try {
      const response = await globalApiCalling("GET", url);

      const data = await response?.json();

      if (data?.code === 1000 || data?.code == 200) {
        if (selectedSection === "Article") {
          const toatalPage = Math.ceil(data?.total / entriesPerPage);
          setTotalPages(toatalPage || 1);
          // setCurrentPage(1);

          const filteredData = data?.data?.map((item) => ({
            Name: item?.Title,
            Content: item?.Content,
            Section: item?.Section?.Name,
            ID: item?.ID,
          }));
          setArticleData(filteredData);
        }

        if (faqId) {
          setSectionName(data?.data);
        }

        // selectedSection === "Q/A" && setFaqData(filtered);
      }
    } catch (error) {
      console.error("Error fetching media:", error);
      toast.error(error?.message);
    }
  };
  const fetchSections = async () => {
    try {
      const response = await globalApiCalling(
        "GET",
        `doublefollowers/v1/help-section/list?limit=${entriesPerPage}&page_no=${currentPage}&name=${searchInput}`
      );
      const data = await response?.json();
      if (data?.code === 1000 || data?.code == 200) {
        const toatalPage = Math.ceil(data?.total / entriesPerPage);
        setTotalPages(toatalPage || 1);
        // setCurrentPage(1);
        const filteredData = data?.data?.map((item) => ({
          Name: item?.Name,
          "No. Articles":
            item?.Articles.length === 0 ? "N/A" : item?.Articles.length,
          ID: item?.ID,
        }));

        setArticleData(filteredData);
      }
    } catch (error) {
      console.error("Error fetching media:", error);
      toast.error(error?.message);
    }
  };
  // useEffect(() => {
  //   setSearchInput("");
  //   showFAQUpsert && fetchCategories();
  // }, [showFAQUpsert]);

  // useEffect(() => {
  //   if (faqId) {
  //     setShowFAQUpsert(true);
  //   }
  // }, [faqId]);
  const handleDelete = async (rowId) => {
    let url;
    url =
      selectedSection === "Article"
        ? `doublefollowers/v1/help-article/bulk/delete`
        : `doublefollowers/v1/help-section/bulk/delete`;
    try {
      const response = await globalApiCalling("DELETE", url, {
        ids: rowId ? rowId : selectedIds,
      });

      const result = await response?.json();

      if (result?.code === 1000 || result?.code === 200) {
        toast.success(t("Success!"));
        setSelectedIds([]);
        if (selectedSection === "Article") {
          fetchArticles();
        } else {
          fetchSections();
        }
        return true;
      } else {
        toast.error(result?.message || t("Failed!"));
        return false;
      }
    } catch (error) {
      toast.error(error?.message);
      console.error(error);
      return false;
    }
  };

  useEffect(() => {
    fetchArticles();
    fetchSections();
  }, []);

  useEffect(() => {
    if (selectedSection === "Article") {
      fetchArticles();
    } else {
      fetchSections();
    }
  }, [currentPage, entriesPerPage]);

  useEffect(() => {
    setFaqData([]);
    setSearchInput("");
    if (selectedSection === "Article") {
      fetchArticles();
      setHeaders(["Name", "Content", "Section"]);
    } else {
      fetchSections();
      setHeaders(["Name", "No. Articles"]);
    }
  }, [selectedSection, searchInput]);
  useEffect(() => {
    setCurrentPage(1);
  }, [entriesPerPage]);

  const moreOptions = [
    { value: "1", label: "Edit" },
    { value: "2", label: "Delete" },
  ];

  const onPageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const onEntriesChange = (entries) => {
    setEntriesPerPage(entries);
  };

  const handleSection = async (e, faqId) => {
    e.preventDefault();

    const validationErrors = validateFormData(
      sectionName,
      t,
      "Help management"
    );
    setErrors(validationErrors);
    if (!isValidForm(validationErrors)) {
      return;
    }

    // if (!sectionName || sectionName?.trim() === "") {
    //   toast.error("Section name cannot be empty");
    //   return;
    // }

    const url = faqId
      ? `doublefollowers/v1/help-section/update`
      : `doublefollowers/v1/help-section/create`;
    const method = faqId ? "PUT" : "POST";
    const payload = faqId
      ? { name: sectionName, id: faqId }
      : { name: sectionName };

    try {
      const response = await globalApiCalling(method, url, payload);
      const data = await response?.json();

      if (data?.code === 200 || data?.code === 1000) {
        toast.success(t(`Success!`));
        fetchSections();
        setOpenModal(false);
        setSectionName("");
      }
    } catch (error) {
      console.error(error.message);
      toast.error(error.message);
    }
  };

  useEffect(() => {
    if (faqId) {
      fetchArticles();
    } else {
      setSectionName("");
    }
  }, [faqId]);
  return (
    <>
      <div className="p-4 template-management-container flex flex-col gap-[25px] admin-faq-container">
        <div className="flex items-center justify-between">
          <div className="flex items-center justify-between">
            <BreadCrumbs items={breadcrumbItems} />
          </div>
        </div>
        <div className="flex items-center justify-between">
          <div className="flex bg-gray-200 p-1 border-radius-10 faq-sections-tag">
            <button
              className={`flex items-center justify-center text-xs px-6 py-[6px] border-radius-8 ${
                selectedSection === "Article"
                  ? "bg-blue-500 text-white"
                  : "text-gray-700"
              }`}
              onClick={() => setSelectedSection("Article")}
            >
              <SvgComponent name={"faq"} />
              <span className="ml-2">{t("Article")}</span>
            </button>
            <button
              className={`flex items-center justify-center text-xs px-6 py-[6px] border-radius-8 ${
                selectedSection === "Section"
                  ? "bg-blue-500 text-white"
                  : "text-gray-700"
              }`}
              onClick={() => setSelectedSection("Section")}
              // onClick={() => setOpenModal(true)}
            >
              <SvgComponent name={"faq"} />
              <span className="ml-2">{t("Section")}</span>
            </button>
          </div>
          <div className="flex items-center space-x-4">
            <div className="relative template-search-wrapper">
              <input
                value={searchInput}
                type="text"
                placeholder={`${t("Search")} ${t(selectedSection)}`}
                className="w-[286px] text-sm px-4 py-2 !pl-9 rounded-full bg-white focus:outline-none focus:ring-2 focus:ring-blue-500"
                onChange={(e) => setSearchInput(e?.target?.value)}
              />
              <span className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 cursor-pointer">
                <SvgComponent name={"GraySearchIcon"} />
              </span>
            </div>
            <button
              className="flex items-center px-4 py-2 bg-blue-500 text-white rounded-full text-sm"
              // onClick={() => setShowFAQUpsert(!showFAQUpsert)}
              onClick={() => {
                setFaqId(null);
                if (selectedSection === "Article") {
                  navigate("/admin/help/create");
                } else {
                  setOpenModal(true);
                }
              }}
            >
              <SvgComponent name={"plusIcon"} />
              {t("Add")} {t(selectedSection)}
            </button>
            {selectedIds?.length > 0 && (
              <button className="" onClick={() => handleDelete(selectedIds)}>
                <SvgComponent name={"RedDeleteIcon"} />
              </button>
            )}
          </div>
        </div>
        <div className="relative">
          {selectedSection && (
            <CommonTable
              headers={headers}
              data={articleData}
              selectedSection={selectedSection}
              // setOpenModal={setShowFAQUpsert}
              setOpenModal={setOpenModal}
              openModal={openModal}
              noDataIcon={"faq"}
              setId={setFaqId}
              selectedIds={selectedIds}
              setSelectedIds={setSelectedIds}
              handleDelete={handleDelete}
              module={"Help"}
              moreOptions={moreOptions}
            />
          )}

          {openModal && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[100]">
              <div className="bg-white rounded-lg shadow-lg w-[500px] p-6 relative">
                <button
                  className="absolute top-4 right-4 w-[30px] h-[30px] bg-[#F0F3FF] flex items-center justify-center rounded-[23px]"
                  onClick={onClose}
                >
                  <SvgComponent name={"crossIcon"} />
                </button>
                <h2 className="text-xl font-semibold mb-4">
                  {faqId ? t("Edit Section") : t("Add Section")}
                </h2>

                <form onSubmit={(e) => handleSection(e, faqId)}>
                  <div className="w-full">
                    <label className="block mb-2 text-sm font-medium text-gray-700">
                      {t(tagTitle)} {t("Name")}
                    </label>
                    <input
                      type="text"
                      value={faqId ? sectionName.Name : sectionName}
                      onChange={(e) => {
                        setErrors("");
                        setSectionName(e.target.value);
                      }}
                      // value={formData?.name}
                      // onChange={handleChange}
                      className={`p-2 border-2 activeInput rounded-[8px] mb-4 w-full ${
                        !faqId && errors.name ? "border-red-500" : ""
                      }`}
                      placeholder={t("Enter Section name")}
                    />
                    {!faqId && errors.name && (
                      <div className="text-red-500 -mt-3 mb-2 text-sm">
                        {errors.name}
                      </div>
                    )}
                  </div>
                  <div className="flex justify-end">
                    <button
                      type="button"
                      onClick={onClose}
                      className="mr-2 bg-gray-200 text-gray-700 px-4 py-2 rounded-lg hover:bg-gray-300"
                    >
                      {t("Cancel")}
                    </button>
                    <button
                      type="submit"
                      className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600"
                    >
                      {isLoading ? (
                        <>
                          <i class="fa fa-circle-o-notch fa-spin mr-1"></i>
                          {t("Processing")}
                        </>
                      ) : faqId ? (
                        t("Edit")
                      ) : (
                        t("Create")
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          )}
        </div>
      </div>
      <Pagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPages={totalPages}
        onPageChange={onPageChange}
        entriesPerPage={entriesPerPage}
        onEntriesChange={onEntriesChange}
      />
    </>
  );
};

export default HelpManagement;
